<template>
  <div class="mt-n6">
    <my-services class="div-my-service" :serviceInfo="index.section2"></my-services>
    <my-announcement class="div-my-announcement"></my-announcement>
    <dialog-error
        ref="dialogError"
        :topic="'Error'"
        :text="errorMessage"
    />
  </div>
</template>

<script>
import MyServices from '../components/MyServices.vue';
import MyAnnouncement from '../components/MyAnnouncement.vue';
import axios from 'axios';
import DialogError from '../components/DialogError.vue';

export default {
    components: {
        MyServices,
        MyAnnouncement,
        DialogError
    },
    data: () => ({
      index: {
        section1: {},
        section2: {}
      },
      errorMessage: ''
    }),
    async mounted(){
      try{
        const response = await axios.get(process.env.VUE_APP_BASE_URL + '/contents/customer-index');
        this.index = response.data;
      }catch(error){
        this.errorMessage = error.response ? Array.isArray(error.response.data) ? error.response.data[0].msg : error.response.data : error.message;
        this.$refs.dialogError.show();
      }
    }
};
</script>